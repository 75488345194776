import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import "../styles/pageNotFound.scss"

const PageNotFound = () => {
  return (
    <Layout>
      <div className="pageNotFound">
        <div className="content">
          Sivua ei löytynyt. <Link to="/">Takaisin etusivulle.</Link>
        </div>
      </div>
    </Layout>
  )
}

export default PageNotFound
